import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import SectionTitle from "../../components/common/SectionTitle"
import Cta from "../../components/common/Cta"
import HeaderImage from "../../components/common/HeaderImage"
import ColumnsThree from "../../components/layouts/container/ColumnsThree"
import ServiceSidebar from "../../components/common/ServiceSidebar"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Leistungen | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          {
            link: "Immobilienbewertung",
            to: "/leistungen/immobilienbewertung/",
          },
        ]}
      />
      <Container className="my-8 md:my-16">
        <ColumnsThree>
          <div className="md:col-span-2">
            <SectionTitle
              title="Immobilienbewertung"
              subtitle="Leistungen"
              text="Wir ermitteln ganz einfach den Wert Ihrer Immobilie."
              className="mb-6"
            />
            <P className="mb-3">
              Der Immobilienmarkt in Deutschland befindet sich einer Boom-Phase.
              Die Preise für Häuser, Wohnungen und Grundstücke steigen
              vielerorts rasant an. Ganz besonders in den Großstädten und
              Ballungszentren kannten die Preise in den vergangenen Jahren nur
              eine Richtung: nach oben.
            </P>
            <P className="mb-3">
              Viele Immobilienbesitzer fragen sich nun, was Ihre Immobilie wert
              ist. Die Preise nicht überall oder gleich schnell. Es gibt große
              Unterschiede zwischen Stadt und Land. Auch auf regionaler Ebene
              geht die Entwicklung oftmals stark auseinander. Zusätzlich
              gestatelt sich die Wertermittlung oft nicht einfach. Sie können
              andere Immobilienanzeigen in Ihrer Region heranziehen. Dieser
              Vergleich ist aber oft sehr ungenau. Jedes Haus und jede Wohnung
              hat unterschiedliche Merkmale und manchmal machen kleine
              Entfernungen von zwei Straßen große Unterschiede bei dem zu
              erzielenden Preis aus.
            </P>
            <P className="mb-3">
              Mit nur wenigen Daten zu Standort und Art Ihrer Immobilie können
              wir eine erste individuelle Einschätzung zum Verkehrswert
              erstellen. Mit der Immobilienbewertung können Sie Ihre Planungen
              vorantreiben und bekommen eine gute Einschätzung des
              Verkaufspreises, den Sie erzielen könnten.
            </P>
          </div>
          <div>
            <ServiceSidebar name="Immobilienbewertung" />
          </div>
        </ColumnsThree>
      </Container>

      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "immobilienbewertung.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
